import styled from "@emotion/styled";
import { Box, meshBounds } from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import * as React from "react";

import { Mesh, Object3D } from "three";

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  font-family: sans-serif;
  font-size: 24px;

  color: #fff;
  background: linear-gradient(
    to bottom,
    hsl(200, 100%, 50%),
    hsl(180, 100%, 50%)
  );
`;

const DropArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Output = styled.textarea`
  background: hsla(0, 0, 100%, 0.2);
`;
const ClickArea = styled.div``;

const Scene = ({ mesh }) => {
  const rotateGroup = React.useRef<Object3D>();
  useFrame(() => {
    rotateGroup.current.rotation.y += 0.01;
  });

  return <group ref={rotateGroup}>{mesh && <primitive object={mesh} />}</group>;
};

const App = () => {
  const [mesh, setMesh] = React.useState<Object3D>();
  const [compoundOutput, setCompountOutput] = React.useState<string>();

  const handleClickDropArea = () => {
    if (compoundOutput) return;
    const dum = document.createElement("input");
    dum.type = "file";

    dum.click();

    dum.onchange = () => {
      console.log(dum.files);
      if (dum.files[0]) {
        let url = URL.createObjectURL(dum.files[0]);

        const l = new GLTFLoader();
        l.load(url, (gltf) => {
          let meshes: Object3D[] = [];

          setMesh(gltf.scene);

          gltf.scene.traverse((obj) => {
            if (obj.type == "Mesh") {
              meshes.push(obj);
            }
          });

          let output = meshes.map((mesh) => {
            const { x, y, z } = mesh.position;
            const size = mesh.scale.x;

            return {
              type: "Sphere",
              position: [x, y, z],
              args: [size],
            };
          });

          setCompountOutput(JSON.stringify(output));
        });
      }
    };
  };

  return (
    <Container>
      <Canvas>
        <Scene mesh={mesh} />

        <ambientLight intensity={0.5} />
        <directionalLight position={[1, 1, 1]} />
      </Canvas>

      <DropArea onClick={handleClickDropArea}>
        {compoundOutput && <Output>{compoundOutput}</Output>}
        {!compoundOutput && <ClickArea>Click to upload</ClickArea>}
      </DropArea>
    </Container>
  );
};

export default App;
